import React from "react"
import AuthForm from "../AuthForm/AuthForm";

function Register({handleCheckRegister}) {
  return (
      //  <AuthForm name={'register'} title={'Добро пожаловать!'}  textButton={'Зарегестрироваться'} handleCheckRegister={handleCheckRegister} />
       <AuthForm name={'register'} title={'Добро пожаловать!'}  textButton={'Зарегестрироваться'} handleCheckRegister={handleCheckRegister} />
  )
};

export default Register;


